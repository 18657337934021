* {
    font-family: Helvetica, sans-serif;
}

.navLink {
    text-decoration: none;
}

main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: calc(100vh - 110px);
    justify-content: start;
    align-content: space-around;
}

footer {
    text-align: center;
    height: 37px;
    width: 100vw;
    border-top: 1px black solid;
    color: black;
}

.flexBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.greenBg {
    background-color: #e3ffe3;
}

.redBg {
    background-color: #ffd5d5;
}

.nullHeightBoxForOverflow {
    height: 0;
}

.boxShadow {
    box-shadow: 0 0 2px 1px rgba(30, 39, 47, 0.67);
}

.opacity {
    opacity: 0.6;
}
