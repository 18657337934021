header {
    color: black;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 23px;
    border-bottom: 1px black solid;
    margin-bottom: 15px;
    padding-bottom: 5px;
}

header button:first-child {
    width: 55vw;
}

header button {
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    margin: 0;
    opacity: 80%;
}
