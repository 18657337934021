
.productCard {
    text-align: center;
    box-shadow: 0 0 2px 1px rgba(234, 242, 248, 0.53);
    font-size: 11px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    color: black;
}

.productCard h3 {
    font-family: Helvetica, sans-serif;
    color: black;
    text-align: center;
    font-weight: bold;
    border-top: 1px black dotted;
    border-bottom: 1px black dotted;
}

.imageAndText {
    display: flex;
    justify-content: space-around;
}

.productCard p {
    color: black;
    margin-left: 10px;
}
